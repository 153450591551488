import React from "react"
import Helmet from "react-helmet"
import config from "config/SiteConfig"
import { EuiGlobalToastList } from "@elastic/eui"
import euiVars from "@elastic/eui/dist/eui_theme_light.json"
import { useToasts } from "src/hooks"
import { Navbar } from "src/components"
import styled, { ThemeProvider } from "styled-components"

import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "src/assets/css/variables.css"
import "src/assets/scss/theme.scss"
import { UiTypes } from "src/types"

const customTheme = {
  ...euiVars,
  // euiTitleColor: "dodgerblue",
}

const LayoutWrapper = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .Layout {
    width: 100%;
  }
  .index-container,
  .app-container,
  .post-container {
    display: flex;
    flex-direction: column;
  }
  & a.small-link {
    font-size: 14px;
    font-size: var(--text-sm);
  }
`

interface ILayout {}
const Layout: React.FC<ILayout> = ({ children }) => {
  const [toasts, { removeToast }] = useToasts()
  React.useEffect(() => {
    if (typeof window !== "undefined" && "serviceWorker" in navigator) {
      window.navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((r) => r.unregister())
      })
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <ThemeProvider theme={customTheme}>
        <LayoutWrapper>
          <Navbar />
          <>{children}</>
          <EuiGlobalToastList
            toasts={toasts}
            dismissToast={(toast) => removeToast(toast as UiTypes.Toast)}
            toastLifeTimeMs={3000}
            side="right"
            className="auth-toast-list"
          />
        </LayoutWrapper>
      </ThemeProvider>
    </>
  )
}

export default Layout
